<template>
  <div class="address">
    <center-title title="账号管理"></center-title>
    <router-link to="/addacount" class="add row-center xs mb20"
      >+ 新增子账号</router-link
    >
    <el-table :data="accountList" style="width: 100%">
      <el-table-column align="center" prop="sn" label="编号" width="120">
      </el-table-column>
      <el-table-column align="center" label="头像" width="120">
        <template slot-scope="scope">
          <el-image
            style="width: 46px; height: 46px; border-radius: 50%"
            :src="scope.row.avatar"
            fit="cover"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="mobile"
        label="手机号码"
        :width="userInfo.company_id == 57 ? 130 : 160"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="group_name"
        label="用户类型"
        width="120"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="true_name"
        label="真实姓名"
        width="120"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="cost_unit"
        label="成本单元"
        width="120"
        v-if="userInfo.company_id == 61"
      >
      </el-table-column>
      <!-- <el-table-column align="center" prop="status" label="状态" width="120">
        <template slot-scope="scope">
          <div class="item lighter" v-if="scope.row.status == 0">审核中</div>
          <div class="item primary" v-if="scope.row.status == 1">审核通过</div>
          <div
            class="item price"
            @click="open(scope.row.explain)"
            v-if="scope.row.status == 2"
          >
            审核不通过<br />(查看原因)
          </div>
        </template>
      </el-table-column> -->
      <!-- 南宁招标需求定制变更 -->
      <el-table-column v-if="userInfo.company_id == 57" align="center" prop="status" label="状态" width="120">
        <template slot-scope="scope">
          <div class="item primary" v-if="scope.row.da_state == 0">审核通过</div>
          <div class="item lighter" v-if="scope.row.da_state == 1">科室主任审核中</div>
          <div class="item lighter" v-if="scope.row.da_state == 2">后勤采购员审核中</div>
          <div class="item lighter" v-if="scope.row.da_state == 3">后勤主任审核中</div>
          <div class="item primary" v-if="scope.row.da_state == 4">审核未通过</div>
          <div class="item primary" v-if="scope.row.da_state == 5">审核通过</div>
        </template>
      </el-table-column>
      <el-table-column v-if="userInfo.company_id != 57" align="center" prop="status" label="状态" width="120">
        <template slot-scope="scope">
          <div class="item lighter" v-if="scope.row.status == 0">审核中</div>
          <div class="item primary" v-if="scope.row.status == 1">审核通过</div>
          <div class="item price" @click="open(scope.row.explain)" v-if="scope.row.status == 2">
            审核不通过<br />(查看原因)
          </div>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        prop="login_ip"
        label="最后登录ip"
        width="140"
      >
      </el-table-column>
      <el-table-column align="center" label="操作" fixed="right" :width="userInfo.company_id == 57 ? 200 : 140">
        <template slot-scope="scope">
          <div class="row-center">
            <router-link
              :to="`/viewcountlist?&id=${scope.row.id}`"
              class="status-item"
              >查看</router-link
            >
            <router-link
              :to="`/editcountlist?&id=${scope.row.id}`"
              class="status-item"
              v-if="scope.row.group_id != 1 && scope.row.status != 2"
            >
              修改</router-link
            >
            <div
              class="status-item cursor"
              @click="delSubAccountApi(scope.row.id)"
              v-if="scope.row.group_id != 1"
            >
              删除
            </div>
            <div @click="auditSubAccountApi(scope.row.id, 1)" class="status-item cursor" v-if="userInfo.company_id == 57 && scope.row.da_state >= 1 && scope.row.da_state <= 3">通过</div>
            <div @click="auditSubAccountApi(scope.row.id, 2, '确定拒绝审核?')" class="status-item cursor" v-if="userInfo.company_id == 57 && scope.row.da_state >= 1 && scope.row.da_state <= 3">拒绝</div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="table" v-if="false">
      <div class="table-item th row lighter">
        <div class="item">编号</div>
        <div class="item">头像</div>
        <div class="item">手机号码</div>
        <div class="item">真实姓名</div>
        <div class="item">状态</div>
        <div class="item">最后登录ip</div>
        <div class="item">操作</div>
      </div>
      <div
        class="table-item row xs"
        v-for="(item, index) in accountList"
        :key="index"
      >
        <div class="item">{{ item.sn }}</div>
        <div class="item">
          <el-image
            style="width: 46px; height: 46px; border-radius: 50%"
            :src="item.avatar"
            fit="cover"
          ></el-image>
        </div>
        <div class="item">{{ item.mobile }}</div>
        <div class="item">{{ item.true_name }}</div>
        <div class="item lighter" v-if="item.status == 0">审核中</div>
        <div class="item primary" v-if="item.status == 1">审核通过</div>
        <div
          class="item price"
          @click="open(item.explain)"
          v-if="item.status == 2"
        >
          审核不通过<br />(查看原因)
        </div>
        <div class="item">{{ item.login_ip }}</div>
        <div class="item status-list">
          <router-link :to="`/viewcountlist?&id=${item.id}`" class="status-item"
            >查看</router-link
          >
          <router-link
            :to="`/editcountlist?&id=${item.id}`"
            class="status-item"
            v-if="item.status != 1"
            >修改
          </router-link>
          <div class="status-item" @click="delSubAccountApi(item.id)">删除</div>
        </div>
      </div>
      <div class="table-item row xs" v-if="!accountList.length">
        <div class="item flex1">暂无子账号</div>
      </div>
    </div>
  </div>
</template>
<script>
import CenterTitle from "../components/CenterTitle";
import Cache from "@/utils/cache";
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
  },
  data() {
    return {
      accountList: [],
      userInfo: {}, //用户信息
    };
  },
  computed: {},
  watch: {},
  methods: {
    open(content) {
      this.$alert(content, "驳回原因", {
        confirmButtonText: "确定",
      });
    },
    // 获取地址列表
    getAccountList() {
      this.$api.getAccountListApi().then((res) => {
        this.accountList = res.data;
      });
    },
    // 设置默认地址
    setDefault(item) {
      if (item.is_default) return;
      this.$api
        .setDefaultApi({
          id: item.id,
        })
        .then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.getAddressList();
        });
    },
    // 删除用户
    delSubAccountApi(id) {
      this.$confirm("此操作将永久删除该账号, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$api
          .delSubAccountApi({
            user_id: id,
          })
          .then((res) => {
            if (res.code == 1) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getAccountList();
            }
          });
      });
    },
    // 审核用户
    auditSubAccountApi(id, state, msg = "确定审核通过?") {
      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$api
          .auditSubAccountApi({
            userid: id,
            state: state
          })
          .then((res) => {
            if (res.code == 1) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              this.getAccountList();
            }
          });
      });
    }
  },
  created() {
    this.userInfo = Cache.get("userInfo")
    this.getAccountList();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.add {
  border: $-solid-border;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}

.item.price {
  cursor: pointer;
}

.table {
  border-top: $-solid-border;
  border-left: $-solid-border;

  .table-item {
    align-items: inherit;

    &.th {
      background-color: $-color-center;
      text-align: center;
    }

    .item {
      min-height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 0;
      line-height: 1.5;
      border-right: $-solid-border;
      border-bottom: $-solid-border;
      text-align: center;

      &.status-list {
        padding: 0 20px;
        justify-content: space-between;

        &:not(.active) {
          .status-item {
            &:hover {
              color: $-color-primary;
              cursor: pointer;
            }
          }
        }
      }

      &:nth-child(1) {
        width: 12%;
      }

      &:nth-child(2) {
        width: 12%;
      }

      &:nth-child(3) {
        width: 18%;
      }

      &:nth-child(4) {
        width: 15%;
      }

      &:nth-child(5) {
        width: 12%;
      }

      &:nth-child(6) {
        width: 15%;
      }

      &:nth-child(7) {
        width: 18%;
      }
    }
  }
}

.status-item {
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
}

/deep/ .el-table {
  border: $-solid-border;
  border-bottom: none;

  th {
    background-color: $-color-center;
    font-size: 16px;
    font-weight: normal;
    color: $-color-lighter;
  }

  td {
    &:nth-child(2) {
    }

    .cell {
      line-height: 1.3;
      word-break: break-all;
    }
  }

  th,
  td {
    border-right: $-solid-border;

    &:last-child {
      border-right: none;
    }
  }
}
</style>
